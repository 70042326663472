import api from ".";

export const createInterphoneApartment = async (towerId: number) => {
  let response = await api.post(`apartment`, {
    name: "Interfone",
    towerId: towerId,
  });
  const data = response.data;

  return data.apartment;
};
