import styles from "./ProgressBar.module.scss";

interface Props {
  parts: (string | undefined)[];
  current: number;
}

export default function ProgressBar({ parts, current }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles["container-blur"]} />
      <div
        className={styles.track}
        style={{ gridTemplateColumns: `repeat(${parts.length}, 1fr)` }}
      >
        {parts.map((item, i) => {
          if (i + 1 <= current)
            return (
              <div key={`track-part-${i}`} className={styles["part-done"]}>
                <div className={styles.checkpoint}>
                  <div className={styles["checkpoint-bullet"]}>
                    <div />
                  </div>
                  <p className={styles["checkpoint-label"]}>{item}</p>
                </div>
              </div>
            );

          return (
            <div key={`track-part-${i}`} className={styles["part-undone"]}>
              <div className={styles["checkpoint"]}>
                <div className={styles["checkpoint-bullet-undone"]}></div>
                <p className={styles["checkpoint-label"]}>{item}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
