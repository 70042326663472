import { useAtom } from "jotai";
import { userInfo } from "../../states/UserState";
import { useNavigate } from "react-router-dom";
import { makeVoxInterphoneLogin } from "../makeVoxInterphoneLogin";

export default function useLoginPersist() {
  const [guest, setGuest] = useAtom(userInfo);

  const navigate = useNavigate();

  return () => {
    const { residentialId } = guest;

    if (!residentialId) {
      const guestJson = localStorage.getItem("guugWebTotem.guestState");

      if (guestJson) {
        const guestData = JSON.parse(guestJson);

        setGuest(guestData);
        makeVoxInterphoneLogin(guestData.phone);

        navigate("/opcoes");
      } else navigate("/configuracao");
    }
  };
}
