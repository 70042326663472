import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  HiOutlineArrowCircleRight,
  HiOutlineChevronDown,
} from "react-icons/hi";
import getToast from "../../utils/getToast";
import SearchBar from "../SearchBar";
import styles from "./SelectInput.module.scss";

interface List {
  label: string;
  value: number;
}

interface Props {
  list: List[];
  setValue: (value: number) => void;
  value: number;
  placeholder: string;
  searchBarPlaceholder: string;
  disabled?: boolean;
  disabledErrorMessage?: string;
}

export default function SelectInput({
  list,
  setValue,
  value,
  placeholder,
  searchBarPlaceholder,
  disabled,
  disabledErrorMessage,
}: Props) {
  const [label, setLabel] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!value) setLabel(null);
  }, [value]);

  return (
    <div>
      {openModal && (
        <div onClick={() => setOpenModal(false)} className={styles.modal}>
          <div
            onClick={(e) => e.stopPropagation()}
            className={styles["modal-content"]}
          >
            <SearchBar
              placeholder={searchBarPlaceholder}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className={styles["modal-content-items"]}>
              {list
                .filter((item) =>
                  item.label
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase())
                )
                .map((item, i) => (
                  <div
                    className={styles["modal-content-item"]}
                    key={`modal-item-${i}`}
                    onClick={() => {
                      setValue(item.value);
                      setLabel(item.label);

                      setTimeout(() => setOpenModal(false), 200);
                    }}
                  >
                    <HiOutlineArrowCircleRight />
                    <span>{item.label}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      <div
        onClick={() => {
          if (list.length > 0) setOpenModal(true);
          else if (disabledErrorMessage)
            getToast(disabledErrorMessage, "error");
        }}
        onMouseDown={() => setIsPressed(true)}
        onMouseUp={() => {
          setTimeout(() => {
            setIsPressed(false);
          }, 100);
        }}
        className={classNames({
          [styles.container]: true,
          [styles.pressed]: isPressed && !disabled,
        })}
      >
        <span>{label ?? placeholder}</span>
        <HiOutlineChevronDown />
      </div>
    </div>
  );
}
