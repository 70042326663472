import api from ".";

export const createInterphoneUser = async (
  residentialId: number,
  condominiumId: number,
  towerId: number,
  apartmentId: number,
  phone: string
) => {
  let interphone = {
    name: "Portaria Interfone",
    residential: residentialId,
    condominium: condominiumId,
    tower: towerId,
    apartment: apartmentId,
    phone,
    password: "123456",
    permission: "resident",
  };

  let response = await api.post("user", interphone);
  return response.data;
};
