import classNames from "classnames";
import styles from "./CheckboxInput.module.scss";
import { HiCheck } from "react-icons/hi";
import { useState } from "react";

interface Props {
  value: boolean;
  setValue: (value: boolean) => void;
  label: string;
}

export default function SelectInput({ value, setValue, label }: Props) {
  const [isPressed, setIsPressed] = useState(false);

  return (
    <div
      onClick={() => setValue(!value)}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => {
        setTimeout(() => {
          setIsPressed(false);
        }, 100);
      }}
      className={classNames({
        [styles.container]: true,
        [styles.pressed]: isPressed,
      })}
    >
      <span>{label}</span>
      <div
        className={classNames({
          [styles.checkbox]: true,
          [styles["checkbox-checked"]]: value,
        })}
      >
        {value && <HiCheck />}
      </div>
    </div>
  );
}
