import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useAtom, useAtomValue } from "jotai";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "./routes";
import { selectedUserInfo } from "./states/SelectedUserState";
import { userInfo } from "./states/UserState";
import { messagesInfo } from "./states/messagesState";
import "./styles.scss";
import api from "./utils/api";

export default function App() {
  const location = useLocation();
  const pathname = location.pathname.replace(/(\/)(.+)(\/?)/, "$2") as any;

  const guest = useAtomValue(userInfo);

  const [messages, setMessages] = useAtom(messagesInfo);
  const selectedUser = useAtomValue(selectedUserInfo);

  const firebaseConfig = {
    apiKey: "AIzaSyBNrY6BXILY9TQi0sFwUzBT1GbyaoKNoX0",
    authDomain: "guugcall.firebaseapp.com",
    databaseURL: "https://guugcall.firebaseio.com",
    projectId: "guugcall",
    storageBucket: "guugcall.appspot.com",
    messagingSenderId: "679493129773",
    appId: "1:679493129773:web:ba674e31aac32a2715caa4",
    measurementId: "G-GWLMNNHNT1",
  };

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  Notification.requestPermission().then(async (permission) => {
    if (permission === "granted") {
      const token = await getToken(messaging);
      await api.post("wp_notification_manager/subscribe", {
        fcm: token,
        topic: String(guest.id),
      });
    }
  });

  onMessage(messaging, (payload) => {
    const { data } = payload;

    if (
      pathname === "mensagens" &&
      data &&
      selectedUser?.id === +data.user_id
    ) {
      setMessages([
        ...messages,
        {
          type: "received",
          text: data.text,
          time: moment().format("HH:mm"),
        },
      ]);
    }
  });

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router />
    </>
  );
}
