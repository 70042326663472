import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  HiIdentification,
  HiLockClosed,
  HiOutlineCheckCircle,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { CondominiumObject } from "../../ObjectTypes";
import configIcon from "../../assets/img/config-icon.png";
import CheckboxInput from "../../components/CheckboxInput";
import FormButton from "../../components/FormButton";
import IconInput from "../../components/IconInput";
import Modal from "../../components/Modal";
import SelectInput from "../../components/SelectInput";
import outletState from "../../states/OutletState";
import { getResidential } from "../../utils/api/getResidential";
import { DropdownType } from "../Intercom";
import styles from "./InitialConfig.module.scss";
import api from "../../utils/api";
import getToast from "../../utils/getToast";
import { acceptUser } from "../../utils/api/acceptUser";
import { createInterphoneApartment } from "../../utils/api/createInterphoneApartment";
import { createInterphoneCondominium } from "../../utils/api/createInterphoneCondominium";
import { createInterphoneTower } from "../../utils/api/createInterphoneTower";
import { createInterphoneUser } from "../../utils/api/createInterphoneUser";
import { getAllApartmentByTower } from "../../utils/api/getAllApartmentByTower";
import { getAllCondominiumByResidential } from "../../utils/api/getAllCondominiumByResidential";
import { getAllTowerByCondominium } from "../../utils/api/getAllTowerByCondominium";
import { userLogin } from "../../utils/api/userLogin";
import { createVoxUser } from "../../utils/createVoxUser";
import { makeVoxInterphoneLogin } from "../../utils/makeVoxInterphoneLogin";
import * as Voximplant from "voximplant-websdk";

export default function InitialConfig() {
  const navigate = useNavigate();

  const [isLogged, setIsLogged] = useState(false);

  const setOutletParams = useSetAtom(outletState);

  const [openModal, setOpenModal] = useState(false);

  const [isExternalTotem, setIsExternalTotem] = useState(false);
  const [noConcierge, setNoConcierge] = useState(false);

  const [residentialList, setResidentialList] = useState<DropdownType[]>([]);
  const [residentialValue, setResidentialValue] = useState<number>(0);

  const [loginUser, setLoginUser] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const listFilterHandler = (
    structureObj: CondominiumObject[]
  ): DropdownType[] => {
    return structureObj
      .filter((item) => item.name !== "Interfone")
      .map((item) => {
        return { label: item.name, value: item.id };
      });
  };

  const getAllResidentials = async () => {
    const data = await getResidential();

    setResidentialList(listFilterHandler(data));
  };

  const voximplantLogin = async (resID: number, resName: string) => {
    const voximplantInstance = Voximplant.getInstance();

    let condominia = await getAllCondominiumByResidential(resID.toString());
    const intercomCondominium = condominia.condominia.find(
      (cond: any) => cond.name === "Interfone"
    );
    const createdIntercomCondominium = await createInterphoneCondominium(resID);
    const condominium = intercomCondominium ?? createdIntercomCondominium;

    const towers = await getAllTowerByCondominium(
      condominium ? condominium.id.toString() : "0"
    );

    const intercomTower = towers.towers.find(
      (item: any) => item.name === "Interfone"
    );
    const createdItercomTower = await createInterphoneTower(condominium.id);
    const tower = intercomTower ?? createdItercomTower;

    const apartments = await getAllApartmentByTower(
      tower ? tower.id.toString() : "0"
    );
    const intercomApartment = apartments.apartments.find(
      (ap: any) => ap.name === "Interfone"
    );
    const createdIntercomApartment = await createInterphoneApartment(tower.id);
    const apartment = intercomApartment ?? createdIntercomApartment;

    let phone = `${resID}${condominium.id}${tower.id}${apartment.id}`;

    const res = await api.post("/user/testPhoneNumber", { phone });
    const { data } = res;
    const { numberAvailable } = data;

    const user = numberAvailable
      ? await createInterphoneUser(
          resID,
          condominium.id,
          tower.id,
          apartment.id,
          phone
        )
      : await userLogin(phone, "123456");

    if (numberAvailable) await acceptUser(user.user.id.toString());

    let voxAuth = await makeVoxInterphoneLogin(phone);
    if (voxAuth?.code === 404) {
      await createVoxUser(phone, "Portaria", "Interfone");
      await makeVoxInterphoneLogin(phone);
    }

    voximplantInstance.on(Voximplant.Events.ConnectionClosed, async () => {
      await makeVoxInterphoneLogin(phone);
    });

    const state = {
      residentialId: resID,
      residentialName: resName,
      id: user.user.id,
      phone,
      condominiumId: condominium.id,
      condominia: condominia.condominia,
      towerId: tower.id,
      towers: towers.towers,
      apartmentId: apartment.id,
      apartments: apartments.apartments,
    };

    localStorage.setItem("guugWebTotem.guestState", JSON.stringify(state));
  };

  const handleLogin: React.FormEventHandler<HTMLFormElement> = async function (
    e
  ) {
    e.preventDefault();

    const residentialName =
      residentialList.find((item) => item.value === residentialValue)?.label ??
      "";

    const json = JSON.stringify({
      residentialId: residentialValue,
      residentialName,
    });
    localStorage.setItem("guugWebTotem.guestState", json);

    const config = JSON.stringify({
      externalTotem: isExternalTotem,
      noConcierge,
    });
    localStorage.setItem("guugWebTotem.totemConfig", config);

    await voximplantLogin(residentialValue, residentialName);

    navigate("/");
  };

  const handlePreLogin: React.FormEventHandler<HTMLFormElement> =
    async function (e) {
      e.preventDefault();

      const res = await api.post("user/totemLogin", {
        login: loginUser,
        password: loginPassword,
      });
      const { data } = res;

      if (!data.user)
        getToast("Erro! Usuário e/ou senha incorreto(s).", "error");
      else setIsLogged(true);
    };

  useEffect(() => {
    getAllResidentials();

    setOutletParams({
      title: null,
      bottom: null,
      top: {
        component: (
          <div className={styles["container-top"]}>
            <img src={configIcon} width={180} alt="" />
            <h1>{isLogged ? "Configurações" : "Entrar"}</h1>
          </div>
        ),
        height: 22,
      },
    });
  }, [isLogged]);

  return (
    <>
      {openModal && (
        <Modal
          title="Senha incorreta!"
          message="Para acessar o modo de Portaria Inteligente, solicite o acesso enviando um e-mail para comercial@guug.com.br"
          confirm={{
            label: "Enviar e-mail",
            action: () => {
              window.open("mailto:comercial@guug.com.br");

              setOpenModal(false);
            },
          }}
          cancel={{
            label: "Cancelar",
            action: () => setOpenModal(false),
          }}
        />
      )}
      <form
        onSubmit={isLogged ? handleLogin : handlePreLogin}
        className={styles.container}
      >
        {isLogged ? (
          <>
            <SelectInput
              list={residentialList}
              setValue={(value) => setResidentialValue(value)}
              value={residentialValue}
              placeholder="Residencial"
              searchBarPlaceholder="Buscar residencial"
            />
            <CheckboxInput
              value={isExternalTotem}
              setValue={setIsExternalTotem}
              label="Totem fixado na área externa"
            />
            <CheckboxInput
              value={noConcierge}
              setValue={setNoConcierge}
              label="Condomínio sem portaria"
            />
          </>
        ) : (
          <>
            <IconInput
              placeholder="Usuário"
              value={loginUser}
              onChange={(event) => {
                setLoginUser(event.target.value);
              }}
              icon={<HiIdentification />}
            />
            <IconInput
              type="password"
              placeholder="Senha"
              value={loginPassword}
              onChange={(event) => {
                setLoginPassword(event.target.value);
              }}
              icon={<HiLockClosed />}
            />
          </>
        )}
        <div className={styles["button-container"]}>
          <FormButton
            label={isLogged ? "Finalizar configuração" : "Confirmar"}
            icon={<HiOutlineCheckCircle />}
          />
        </div>
      </form>
    </>
  );
}
