import React, { useState } from "react";
import styles from "./IconInput.module.scss";
import classNames from "classnames";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  icon: React.ReactNode;
}

export default function IconInput({ icon, ...rest }: Props) {
  const [isPressed, setIsPressed] = useState(false);

  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.pressed]: isPressed,
      })}
    >
      <input
        onFocus={() => setIsPressed(true)}
        onBlur={() => setIsPressed(false)}
        {...rest}
      />
      {icon}
    </div>
  );
}
