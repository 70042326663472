import styles from "./DefaultPage.module.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../Header";
import Title from "../Title";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import outletState from "../../states/OutletState";
import { useEffect, useMemo } from "react";
import Squares from "../Squares";
import useLoginPersist from "../../utils/hooks/useLoginPersist";
import backButtonState from "../../states/BackButtonState";
import useSetTotemConfig from "../../utils/hooks/useSetTotemConfig";
import classNames from "classnames";
import { messagesInfo } from "../../states/messagesState";

type Paths = "administracao" | "interfone" | "mensagens";
const screenTitles = {
  administracao: "Interfonar",
  interfone: "Interfonar",
  mensagens: "Mensagens",
};

const pagesWithDifferentBackButton = ["interfone", "administracao"];

export default function DefaultPage() {
  const setTotemConfig = useSetTotemConfig();
  const loginPersist = useLoginPersist();

  const params = useAtomValue(outletState);
  const setMessages = useSetAtom(messagesInfo);
  const [backButton, setBackButton] = useAtom(backButtonState);

  const location = useLocation();

  const pathname = location.pathname.replace(
    /(\/)([a-z]+)(\/?\w{0,})/,
    "$2"
  ) as any;
  const headerTitle = screenTitles[pathname as Paths];

  const contentHeight = useMemo(() => {
    const titleHeight = params.title ? "100px" : 0 + "px";
    const bottomComponentHeight =
      (params.bottom?.height
        ? params.bottom?.blurBindingDistance
          ? params.bottom?.height - params.bottom?.blurBindingDistance
          : params.bottom?.height
        : 0) + "rem";
    const topComponentHeight = (params.top?.height ?? 0) + "rem";
    const headerHeight = pathname !== "opcoes" ? "210px" : "450px";

    return `calc(100vh - ${headerHeight} - ${titleHeight} - ${bottomComponentHeight} - ${topComponentHeight})`;
  }, [
    params.title,
    params.bottom?.height,
    params.bottom?.blurBindingDistance,
    params.top?.height,
    pathname,
  ]);

  useEffect(() => {
    loginPersist();
    setTotemConfig();
  }, []);

  useEffect(() => {
    if (!pagesWithDifferentBackButton.includes(pathname))
      setBackButton({ action: undefined });

    if (pathname !== "mensagens") setMessages([]);
  }, [pathname]);

  return (
    <main className={styles.main}>
      <Header
        backAction={backButton.action}
        title={headerTitle}
        optionsHeader={pathname === "opcoes"}
        chatHeader={pathname === "mensagens"}
      />
      <div
        className={classNames({
          [styles.container]: pathname !== "mensagens",
          [styles["chat-container"]]: pathname === "mensagens",
        })}
      >
        {params.title && <Title>{params.title}</Title>}
        {params.top && (
          <div
            style={{ height: params.top.height + "rem" }}
            className={styles["content-top"]}
          >
            {params.top.component}
          </div>
        )}
        <div
          id={pathname === "mensagens" ? "chat" : ""}
          style={{ height: contentHeight }}
          className={styles.content}
        >
          <Outlet />
        </div>
        <div className={styles.blur} />
        {params.bottom && (
          <div
            style={{ height: params.bottom.height + "rem" }}
            className={styles["content-bottom"]}
          >
            {params.bottom.component}
          </div>
        )}
      </div>
      <div className={styles.bottom}>
        <div className={styles["bottom-blur"]} />
        {pathname !== "mensagens" && <Squares type="bottom" />}
      </div>
    </main>
  );
}
