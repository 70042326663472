import classNames from "classnames";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import UserCard from "../../components/UserCard";
import outletState from "../../states/OutletState";
import { totemConfigInfo } from "../../states/TotemConfigState";
import { User, userInfo } from "../../states/UserState";
import getAdmin from "../../utils/api/getAdmin";
import styles from "./Administration.module.scss";
import { useNavigate } from "react-router-dom";
import backButtonState from "../../states/BackButtonState";

interface AdministrationState {
  aptManager: Array<User>;
  generalManager: Array<User>;
  localPorter: Array<User>;
  generalPorter: Array<User>;
  janitor: Array<User>;
}

export interface AdministrationProps {
  administration: AdministrationState;
  messageAnAdmin: (
    adminId: number,
    adminName: string,
    adminPhone: string,
    shouldCallVoip: boolean
  ) => void;
  resetTimeout: () => void;
}

export default function AdministrationComponent() {
  const navigate = useNavigate();

  const [guest] = useAtom(userInfo);

  const totemConfig = useAtomValue(totemConfigInfo);
  const setOutletParams = useSetAtom(outletState);

  const setBackButton = useSetAtom(backButtonState);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const [users, setUsers] = useState<User[]>([]);

  const getAdministrationData = async () => {
    const responseAPI = await getAdmin(guest.residentialId);

    const newAdministration: User[] = [];
    responseAPI.users.forEach((user: User) => {
      if (user) {
        if (user.id !== guest.id) {
          if (
            !user.permission.includes("Manager") ||
            (user.permission.includes("Manager") &&
              totemConfig &&
              !totemConfig.externalTotem)
          )
            newAdministration.push(user);
        }
      }
    });
    setUsers(newAdministration);
  };

  const handleScreenTimeout = useCallback(() => {
    const id = setTimeout(() => {
      navigate("/opcoes");
    }, 60000); // 1 min

    setTimeoutId(id);
  }, [navigate]);

  useEffect(() => {
    setBackButton({
      action: () => {
        clearTimeout(timeoutId);
        navigate(-1);
      },
    });

    handleScreenTimeout();

    getAdministrationData();

    setOutletParams({
      title: "Falar com porteiro",
      top: null,
      bottom: { component: null, height: 2 },
    });
  }, []);

  return (
    <div
      className={classNames({
        [styles["one-column-grid"]]: true,
        [styles["cards-margin-bottom"]]: true,
        [styles["horizontal-padding"]]: true,
      })}
    >
      {users.map((user) => {
        return (
          <UserCard
            onCall={() => clearTimeout(timeoutId)}
            key={`apt-manager-${user.id}`}
            user={user}
          />
        );
      })}
    </div>
  );
}
