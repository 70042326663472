import { TypeOptions, toast } from "react-toastify";

function getToast(message: string, type: TypeOptions, time?: number) {
  return toast(message, {
    type,
    position: "top-center",
    autoClose: time ?? 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    closeButton: false,
  });
}

export default getToast;
