import { Routes, Route } from "react-router-dom";
import React from "react";
import DefaultPage from "./components/DefaultPage";
import Administration from "./pages/Administration";
import Intercom from "./pages/Intercom";
import Chat from "./pages/Chat";
import Call from "./pages/Call";
import InitialConfig from "./pages/InitialConfig";
import Options from "./pages/Options";
import Loading from "./pages/Loading";

export default function Router() {
  return (
    <Routes>
      <Route index element={<Loading />} />
      <Route element={<DefaultPage />}>
        <Route path="configuracao" element={<InitialConfig />} />
        <Route path="administracao" element={<Administration />} />
        <Route path="interfone" element={<Intercom />} />
        {/* <Route path="mensagens" element={<Chat />} /> */}
        <Route path="chamada" element={<Call />} />
        <Route path="opcoes" element={<Options />} />
      </Route>
    </Routes>
  );
}
