import * as Voximplant from "voximplant-websdk";

const client = Voximplant.getInstance();

type AuthResult = {
  displayName?: string;
  name: string;
  result: boolean;
  tokens?: {
    accessExpire: number;
    accessToken: string;
    refreshExpire: number;
    refreshToken: string;
  };
  code?: number;
};

export type Call = {
  clientState: Voximplant.ClientState;
  number: string;
  callID: string;
  name: string;
};

export const callInitialState: Call = {
  clientState: Voximplant.ClientState.DISCONNECTED,
  number: "",
  callID: "",
  name: "",
};

const callVariables: Call = callInitialState;

export async function makeVoxInterphoneLogin(userNumber: string) {
  try {
    const { alreadyInitialized } = client;
    if (!alreadyInitialized) {
      await client.init({});
      await client.connect();
    }
    let authResult: AuthResult = await client.login(
      userNumber + "@guugcall.brunooliveira.n2.voximplant.com",
      "123456"
    );

    const state = client.getClientState();
    callVariables.clientState = state;

    return authResult;
  } catch (e) {
    return e as AuthResult;
  }
}
