import { useAtomValue, useSetAtom } from "jotai";
import { HiOutlineAnnotation, HiOutlinePhoneOutgoing } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { selectedUserInfo } from "../../states/SelectedUserState";
import { totemConfigInfo } from "../../states/TotemConfigState";
import { User, userInfo } from "../../states/UserState";
import useStartCall from "../../utils/hooks/useStartCall";
import Button from "../Button";
import styles from "./UserCard.module.scss";

interface Props {
  user: User;
  onCall: () => void;
}

type Permissions =
  | "localPorter"
  | "generalPorter"
  | "aptManager"
  | "generalManager"
  | "janitor";

const permissionsLabel = {
  localPorter: "Porteiro local",
  generalPorter: "Porteiro geral",
  aptManager: "Síndico",
  generalManager: "Síndico geral",
  janitor: "Zelador",
};

export default function UserCard({ user, onCall }: Props) {
  const guest = useAtomValue(userInfo);
  const navigate = useNavigate();

  const setSelectedUser = useSetAtom(selectedUserInfo);

  const startCall = useStartCall();

  const totemConfig = useAtomValue(totemConfigInfo);

  return (
    <div className={styles.container}>
      <div className={styles["container-name"]}>
        <p>{user.name}</p>
      </div>
      <div className={styles["container-permission"]}>
        <p>{permissionsLabel[user.permission as Permissions]}</p>
      </div>
      <div className={styles["container-buttons"]}>
        <Button
          onClick={() => {
            onCall();

            startCall(
              {
                ...user,
                tower: user.tower?.name,
                apartment: user.apartment?.name,
              },
              guest
            );
          }}
          size="sm"
          label="Ligar"
          icon={<HiOutlinePhoneOutgoing />}
          styleType="third-blue"
        />
        {/* {!totemConfig?.externalTotem && (
          <button
            className={styles["container-buttons-messages"]}
            onClick={() => {
              const selectedUser = {
                id: user.id,
                name: user.name,
                tower: user.tower?.name,
                apartment: user.apartment?.name,
                phone: user.phone,
                shouldCallVoip: user.shouldCallVoip,
              };
              setSelectedUser(selectedUser);

              navigate("/mensagens");
            }}
          >
            <HiOutlineAnnotation />
          </button>
        )} */}
      </div>
    </div>
  );
}
