import { atom } from "jotai";

type InitialState = {
  action?: () => void;
};

const initialState: InitialState = { action: undefined };

const backButtonState = atom(initialState);

export default backButtonState;
