import styles from "./Squares.module.scss";

const bottomColors = ["second-blue", "first-blue", "pink"];

interface Props {
  type: "top" | "bottom";
}

export default function Squares({ type }: Props) {
  return (
    <div className={styles[`${type}-squares`]}>
      {Array.from(Array(3)).map((item, i) => (
        <div
          key={`${type}-square-${i}`}
          className={styles.square}
          style={{ backgroundColor: `var(--${bottomColors[i]})` }}
        />
      ))}
    </div>
  );
}
