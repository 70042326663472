import { atom } from "jotai";
import { ReactNode } from "react";

interface Bottom {
  component: ReactNode;
  height: number;
  blurBindingDistance?: number;
}

interface Top {
  component: ReactNode;
  height: number;
}

interface OutletState {
  title: ReactNode | null;
  bottom: Bottom | null;
  top: Top | null;
}

const initialState: OutletState = {
  title: null,
  bottom: null,
  top: null,
};

const outletState = atom(initialState);

export default outletState;
