import axios from "axios";
import { useAtom, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Voximplant from "voximplant-websdk";
import { GuestInfo } from "../../ObjectTypes";
import { callInfo } from "../../states/CallState";
import { SelectedUser, selectedUserInfo } from "../../states/SelectedUserState";
import api from "../api";
import getToast from "../getToast";
import { Call, callInitialState } from "../makeVoxInterphoneLogin";
import { callConnectedInfo } from "../../states/callConnectedState";
import { createVoxUser } from "../createVoxUser";
import { now } from "moment";

const client = Voximplant.getInstance();

const getNewUuid = () => uuidv4().toLowerCase();

type CallLogData = {
  idUserFrom: number;
  idUserTo: number;
};

const callVariables: Call = callInitialState;

const getEndCallToast = () => getToast("Ligação finalizada!", "info");

export const useHangup = () => {
  const navigate = useNavigate();

  const setIsCallConnected = useSetAtom(callConnectedInfo);
  const [currentCall, setCurrentCall] = useAtom(callInfo);

  return () => {
    if (currentCall) {
      try {
        currentCall.hangup();

        setCurrentCall(null);
        setIsCallConnected(false);
      } catch (e) {}
    }

    navigate("/opcoes");
  };
};

type VoxLoginUser = {
  phone: string;
  name: string;
};

async function voxImplantLogin(user: VoxLoginUser) {
  const voxImplantUrl = "@guugcall.brunooliveira.n2.voximplant.com";

  const voxImplantClient = Voximplant.getInstance();
  const { alreadyInitialized } = voxImplantClient;

  if (!alreadyInitialized) await voxImplantClient.init({});

  await voxImplantClient.connect();

  try {
    await voxImplantClient.login(`${user.phone}${voxImplantUrl}`, "123456");
  } catch (e: any) {
    console.log(e);

    if (e.code === 404)
      await axios.post(
        `https://api.voximplant.com/platform_api/AddUser/?account_id=3822088&api_key=1e2f1400-2932-4794-bcef-190da3d09425&user_name=${user.phone}&user_display_name=${user.name}
        }&user_password=123456&application_id=10241942`
      );

    await voxImplantClient.login(`${user.phone}${voxImplantUrl}`, "123456");
  }
}

export function useMakeVoxCall() {
  const setIsCallConnected = useSetAtom(callConnectedInfo);
  const setCurrentCall = useSetAtom(callInfo);

  const hangup = useHangup();

  return async (phone: string, callLogData: CallLogData) => {
    try {
      let initialTime = 0;

      const call = client.call(phone);
      setCurrentCall(call);

      const response = await api.post("/call_register", callLogData);
      const logDataId = await response.data.id;

      if (call) {
        call.on(Voximplant.CallEvents.Connected, async (e: any) => {
          initialTime = now();

          await api.put("/call_register", {
            id: logDataId,
            isCallAnswered: true,
          });

          setIsCallConnected(true);
        });
        call.on(Voximplant.CallEvents.Disconnected, async (e: any) => {
          const endTime = now();
          const time =
            initialTime > 0 ? (endTime - initialTime) / 1000 : initialTime;

          await api.put("/call_register", {
            id: logDataId,
            callTime: time,
          });

          hangup();

          setIsCallConnected(false);

          getEndCallToast();
        });
        call.on(Voximplant.CallEvents.Failed, (e: any) => {
          hangup();

          setIsCallConnected(false);

          getEndCallToast();
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
}

function useCall() {
  const makeVoxCall = useMakeVoxCall();

  return async (
    isVoip: boolean,
    callNumber: string,
    callName: string,
    callLogData: CallLogData
  ) => {
    callVariables.callID = getNewUuid();
    callVariables.name = callName;

    callVariables.number = isVoip ? callNumber : "55" + callNumber;

    const voximplantInstance = Voximplant.getInstance();
    const { alreadyInitialized, loginState } = voximplantInstance;

    await makeVoxCall(callVariables.number, callLogData);

    /* if (alreadyInitialized)
      if (loginState) {
        await makeVoxCall(callVariables.number, callLogData);
      } else {
        await voxImplantLogin({
          name: callName,
          phone: callNumber,
        });
        await makeVoxCall(callVariables.number, callLogData);
      }
    else {
      await voximplantInstance.connect();
      await voxImplantLogin({
        name: callName,
        phone: callNumber,
      });
      await makeVoxCall(callVariables.number, callLogData);
    } */
  };
}

export default function useStartCall() {
  const navigate = useNavigate();

  const setSelectedUser = useSetAtom(selectedUserInfo);

  const call = useCall();

  return async (user: SelectedUser, guest: GuestInfo) => {
    const { name, tower, apartment, phone, shouldCallVoip } = user;

    const towerName = tower ?? "";
    const apartmentName = apartment ?? "";

    await call(user.shouldCallVoip, user.phone, user.name, {
      idUserFrom: guest.id,
      idUserTo: user.id,
    });

    const selectedUser = {
      id: user.id,
      name,
      tower: towerName,
      apartment: apartmentName,
      phone,
      shouldCallVoip,
    };
    setSelectedUser(selectedUser);

    navigate("/chamada");
  };
}
