import axios from "axios";

export async function createVoxUser(
  phone: string,
  name: string,
  surname: string | null
) {
  try {
    let response = await axios.get(
      `https://api.voximplant.com/platform_api/AddUser/?account_id=3822088&api_key=1e2f1400-2932-4794-bcef-190da3d09425&user_name=${phone}&user_display_name=${
        surname == null ? name : name + " " + surname
      }&user_password=123456&application_id=10241942`
    );
    return response;
  } catch (e) {
    console.log(e);
  }
}
