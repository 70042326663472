import classNames from "classnames";
import styles from "./ButtonKey.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: React.ReactNode;
  action: React.MouseEventHandler<HTMLButtonElement>;
  height: number;
  width?: number;
}

export default function ButtonKey({
  label,
  action,
  height,
  width,
  className,
  ...rest
}: Props) {
  return (
    <button
      {...rest}
      style={{ height, width: width ?? "100%" }}
      onClick={action}
      className={classNames(styles.button, className)}
    >
      {label}
    </button>
  );
}
