import api from ".";
import { encrypt } from "../crypto";

export const userLogin = async (phone: string, password: string) => {
  let res = await api.post("/user/login", { phone, password });

  const { data } = res;
  const { token } = data;

  if (token) localStorage.setItem("jwt-token", encrypt(token));

  return data;
};
