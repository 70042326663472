import styles from "./Modal.module.scss";

type Button = {
  label: string;
  action: () => void;
};

interface Props {
  title: string;
  message?: string;
  confirm: Button;
  cancel: Button;
}

export default function Modal({ title, message, confirm, cancel }: Props) {
  return (
    <div onClick={cancel.action} className={styles.modal}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={styles["modal-content"]}
      >
        <div className={styles["modal-title"]}>
          <h1>{title}</h1>
        </div>
        <div className={styles["modal-message"]}>
          <p>{message}</p>
        </div>
        <div className={styles["modal-buttons"]}>
          <button className={styles["modal-confirm"]} onClick={confirm.action}>
            {confirm.label}
          </button>
          <button className={styles["modal-cancel"]} onClick={cancel.action}>
            {cancel.label}
          </button>
        </div>
      </div>
    </div>
  );
}
