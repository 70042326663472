import api from ".";

export const getAllCondominiumByResidential = async (residentialId: string) => {
  try {
    // /residential/getCondominia
    let response = await api.get(`/residential/getCondominia/${residentialId}`);
    return response.data;
  } catch (error: any) {
    return error;
  }
};
