import { atom } from "jotai";

export interface SelectedUser {
  id: number;
  name: string;
  surname?: string;
  tower?: string;
  apartment?: string;
  phone: string;
  shouldCallVoip: boolean;
}

export const selectedUserInitialState = null;

export const selectedUserInfo = atom<SelectedUser | null>(
  selectedUserInitialState
);
