import api from ".";

export const createInterphoneCondominium = async (residentialId: number) => {
  let response = await api.post(`/condominium`, {
    name: "Interfone",
    residentialId: residentialId,
  });

  return response.data.condominium;
};
