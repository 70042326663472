import api from ".";

export const getAllApartmentByTower = async (towerId: string) => {
  try {
    let response = await api.get(`/tower/getApartments/${towerId}`);
    return response.data;
  } catch (error: any) {
    return error;
  }
};
