import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import { HiOfficeBuilding, HiOutlinePhoneOutgoing } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import outletState from "../../states/OutletState";
import { totemConfigInfo } from "../../states/TotemConfigState";
import styles from "./Options.module.scss";

export default function Options() {
  const totemConfig = useAtomValue(totemConfigInfo);
  const setOutletParams = useSetAtom(outletState);

  const navigate = useNavigate();

  useEffect(() => {
    setOutletParams({
      title: null,
      top: null,
      bottom: null,
    });
  }, []);

  return (
    <div className={styles.container}>
      <Button
        onClick={() => navigate("/interfone")}
        size="lg"
        label="Interfonar"
        icon={<HiOutlinePhoneOutgoing />}
        styleType="second-blue"
      />
      {!totemConfig?.noConcierge && (
        <Button
          onClick={() => navigate("/administracao")}
          size="sm"
          label="Portaria"
          icon={<HiOfficeBuilding />}
          styleType="outlined"
        />
      )}
    </div>
  );
}
