import api from ".";
import { User } from "../../states/UserState";

export default async function getAdmin(
  residentialId: number
): Promise<{ users: User[] }> {
  let response = await api.get(`/user/administration/${residentialId}`);

  return response.data;
}
