import { atom } from "jotai";
import { ImageDefaultType, ReleaseMode } from "../ObjectTypes";

export type TowerUser = {
  id: number;
  name: string;
  condominiumId: number;
  createdAt: string;
  updatedAt: string;
};
export type ResidentialUser = {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
};
export type ApartmentUser = {
  id: number;
  name: string;
  towerId: number;
  createdAt: string;
  updatedAt: string;
};
export type CondominiumUser = {
  createdAt: string;
  id: number;
  name: string;
  passwordType: "DAILY" | "WEEKLY";
  releaseMode: ReleaseMode;
  residentialId: number;
  updatedAt: string;
};

export type PermissionUser =
  | "resident"
  | "aptManager"
  | "localPorter"
  | "generalPorter"
  | "generalManager"
  | "janitor";

type Door = {
  apartment: { id: number; name: string };
  apartmentId: number;
  condominium: { id: number; name: string };
  condominiumId: number;
  createdAt: string;
  distanceRequest: boolean;
  groupName: string | null;
  id: number;
  name: string;
  onlyResidentRequest: boolean;
  residential: { id: number; name: string };
  residentialId: number;
  tower: { id: number; name: string };
  towerId: number;
  updatedAt: string;
};
type ModeType = "OUT" | "IN" | "BOTH";

export type UserState = {
  automation: {
    Door: Door;
    brand: string;
    createdAt: string;
    deviceId: string;
    doorId: number;
    id: number;
    mode: ModeType;
    name: string;
    type: ModeType;
    updatedAt: string;
  };
  cond: number;
  res: number;
  residentialName: string;
};

export type User = {
  imageUUID: string | null;
  id: number;
  name: string;
  surname?: string;
  password: string;
  confirmPassword: string | null;
  phone: string;
  permission: PermissionUser;
  residentialId: number;
  residentialName: string;
  condominiumId: number;
  apartment?: ApartmentUser;
  apartmentId: number;
  condominium?: CondominiumUser;
  createdAt: string;
  residential?: ResidentialUser;
  shouldCallVoip: boolean;
  status: "accepted" | "denied" | "pending";
  tower?: TowerUser;
  towerId: number;
  updatedAt: string;
  uuid: string;
};

export type ApartmentObject = {
  createdAt: Date;
  id: number;
  name: string;
  towerId: number;
  updatedAt: Date;
};

export type CondominiumObject = {
  createdAt: Date;
  id: number;
  name: string;
  passwordType: "DAILY" | "WEEKLY";
  releaseMode: ReleaseMode;
  residentialId: number;
  updatedAt: Date;
};

export type TowerObject = {
  createdAt: Date;
  id: number;
  name: string;
  condominiumId: number;
  updatedAt: Date;
  condominium?: CondominiumObject;
};

type GuestInfo = {
  id: number;
  phone: string;
  residentialName: string;
  residentialId: number;
  condominiumId: number;
  condominia: CondominiumObject[];
  towerId: number;
  towers: TowerObject[];
  apartmentId: number;
  apartments: ApartmentObject[];
  qrCodeUUID?: string;
  automation: {
    Door: Door;
    brand: string;
    createdAt: string;
    deviceId: string;
    doorId: number;
    id: number;
    mode: ModeType;
    name: string;
    type: ModeType;
    updatedAt: string;
  };
};

export type UpdateUser = {
  image: ImageDefaultType | null;
  id: number;
  name: string;
  surname: string;
  password: string | null;
};

const initialUser: GuestInfo = {} as GuestInfo;

export const userInfo = atom(initialUser);
