import React from "react";
import { HiOutlineSearch } from "react-icons/hi";
import styles from "./SearchBar.module.scss";

interface Props {
  placeholder: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export default function SearchBar({ placeholder, value, onChange }: Props) {
  return (
    <div className={styles.container}>
      <HiOutlineSearch />
      <input placeholder={placeholder} value={value} onChange={onChange} />
    </div>
  );
}
