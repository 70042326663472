import styles from "./FormButton.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  icon: React.ReactNode;
}

export default function FormButton({ label, icon, ...rest }: Props) {
  return (
    <button {...rest} className={styles.button}>
      {icon}
      <span>{label}</span>
    </button>
  );
}
