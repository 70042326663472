import classNames from "classnames";
import styles from "./Button.module.scss";

interface Props {
  onClick: () => void;
  size: "sm" | "lg";
  label: string;
  icon: React.ReactNode;
  styleType: "second-blue" | "third-blue" | "outlined";
}

export default function Button({
  onClick,
  size,
  label,
  icon,
  styleType,
}: Props) {
  return (
    <button
      className={classNames(styles[size], styles[styleType], styles.button)}
      onClick={onClick}
    >
      <div>
        {icon}
        <span>{label}</span>
      </div>
    </button>
  );
}
