import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/header-logo-big.png";
import Squares from "../../components/Squares";
import { userInfo } from "../../states/UserState";
import useSetTotemConfig from "../../utils/hooks/useSetTotemConfig";
import styles from "./Loading.module.scss";
import api from "../../utils/api";
import { encrypt } from "../../utils/crypto";

export default function Loading() {
  const setGuest = useSetAtom(userInfo);

  const setTotemConfig = useSetTotemConfig();
  const navigate = useNavigate();

  useEffect(() => {
    const json = localStorage.getItem("guugWebTotem.guestState");

    if (json) {
      const state = JSON.parse(json);

      api
        .post("/user/loginPersistent", {
          id: state.id,
          uuid: state.uuid,
        })
        .then((res) => res.data)
        .then((user: any) => {
          if (user.token) {
            localStorage.setItem("jwt-token", encrypt(user.token));
          }
        });

      setGuest(state);

      setTotemConfig();

      navigate("/opcoes");
    } else navigate("/configuracao");
  }, []);

  return (
    <div className={styles.background}>
      <Squares type="top" />
      <img className={styles["background-logo"]} src={logo} alt="" />
      <Squares type="bottom" />
    </div>
  );
}
