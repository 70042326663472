import api from ".";

export const createInterphoneTower = async (condominiumId: number) => {
  let response = await api.post("/tower", {
    name: "Interfone",
    condominiumId: condominiumId,
  });

  return response.data.tower;
};
