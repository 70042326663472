import api from ".";

export const getAllTowerByCondominium = async (condominiumId: string) => {
  try {
    let response = await api.get(`/condominium/getTowers/${condominiumId}`);
    return response.data;
  } catch (error: any) {
    return error;
  }
};
