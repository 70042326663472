import { useSetAtom } from "jotai";
import { TotemConfig } from "../../ObjectTypes";
import { totemConfigInfo } from "../../states/TotemConfigState";

export default function useSetTotemConfig() {
  const setTotemConfig = useSetAtom(totemConfigInfo);

  return async () => {
    const json = localStorage.getItem("guugWebTotem.totemConfig") as string;
    const data: TotemConfig = JSON.parse(json);

    setTotemConfig(data);
  };
}
