import { useAtomValue } from "jotai";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlinePhoneOutgoing,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import biggerLogo from "../../assets/img/header-logo-big.png";
import logo from "../../assets/img/header-logo.png";
import { selectedUserInfo } from "../../states/SelectedUserState";
import { userInfo } from "../../states/UserState";
import useStartCall from "../../utils/hooks/useStartCall";
import styles from "./Header.module.scss";

interface Props {
  title?: string;
  optionsHeader?: boolean;
  chatHeader?: boolean;
  backAction?: () => void;
}

export default function Header({
  title,
  optionsHeader,
  chatHeader,
  backAction,
}: Props) {
  const navigate = useNavigate();

  const guest = useAtomValue(userInfo);

  const selectedUser = useAtomValue(selectedUserInfo);

  const startCall = useStartCall();

  return !optionsHeader ? (
    <header className={styles.header}>
      {title ? (
        <div className={styles["header-container"]}>
          <button
            className={styles["header-back-button"]}
            onClick={() => {
              if (backAction) backAction();
              else navigate(-1);
            }}
          >
            <HiOutlineArrowNarrowLeft />
          </button>
          <h1 className={styles["header-title"]}>{title}</h1>
          {chatHeader ? (
            <button
              onClick={() => {
                if (selectedUser) startCall(selectedUser, guest);
              }}
              className={styles["call-button"]}
            >
              <HiOutlinePhoneOutgoing />
            </button>
          ) : (
            <img className={styles["header-logo"]} src={logo} alt="" />
          )}
        </div>
      ) : (
        <img className={styles["header-logo"]} src={logo} alt="" />
      )}
    </header>
  ) : (
    <header className={styles["options-header"]}>
      <img className={styles["options-header-logo"]} src={biggerLogo} alt="" />
      <div className={styles["options-header-title"]}>
        <p>Bem-vindo{guest.residentialName ? " ao" : ""}</p>
        {guest.residentialName && <p>{guest.residentialName}</p>}
      </div>
    </header>
  );
}
