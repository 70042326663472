import axios from "axios";
import { decrypt } from "../crypto";

export const baseURL: string = "https://apiv1.guug.com.br";
// export const baseURL: string = 'http://192.168.1.73:3002';
// export const baseURL: string = "https://dev.guug.com.br";

export const awsUrl = "https://guugcall-prod.s3-sa-east-1.amazonaws.com";

const token = localStorage.getItem("jwt-token")
  ? decrypt(localStorage.getItem("jwt-token") as string)
  : "";

const api = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${token}` },
});

export default api;
