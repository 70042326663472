import React, { useCallback, useEffect, useState } from "react";
import styles from "./Intercom.module.scss";

import classNames from "classnames";
import { useAtom, useSetAtom } from "jotai";
import { HiOutlineArrowCircleRight, HiOutlineUser } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { CondominiumObject } from "../../ObjectTypes";
import ButtonKey from "../../components/ButtonKey";
import ProgressBar from "../../components/ProgressBar";
import SearchBar from "../../components/SearchBar";
import UserCard from "../../components/UserCard";
import backButtonState from "../../states/BackButtonState";
import outletState from "../../states/OutletState";
import { User, userInfo } from "../../states/UserState";
import getApartmentsWithUsers from "../../utils/api/getApartments";
import getCondominia from "../../utils/api/getCondominia";
import getTowers from "../../utils/api/getTowers";
import getUsersByApartment from "../../utils/api/getUsersByApartment";
import welcomeMp3 from "../../assets/audio/Torre_e_Apartamento.mp3";
import getToast from "../../utils/getToast";

export type DropdownType = {
  value: number;
  label: string;
};

type BackTo = "apartments" | "towers" | "condominiums" | "home";

export default function Intercom() {
  const navigate = useNavigate();

  const [backTo, setBackTo] = useState<BackTo>("home");

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const [user] = useAtom(userInfo);
  const setBackButton = useSetAtom(backButtonState);
  const setOutletParams = useSetAtom(outletState);

  const [condominium, setCondominium] = useState<number | null>(null);
  const [condominiumList, setCondominiumList] = useState<DropdownType[]>([]);
  const [tower, setTower] = useState<number | null>(null);
  const [towerList, setTowerList] = useState<DropdownType[]>([]);
  const [apartment, setApartment] = useState<number | null>(null);
  const [apartmentList, setApartmentList] = useState<DropdownType[]>([]);
  const [contactList, setContactList] = useState<User[]>([]);

  const [search, setSearch] = useState("");
  const [mainContent, setMainContent] = useState<React.ReactNode>("");
  const [trackParts, setTrackParts] = useState<(string | undefined)[]>([]);

  const listFilterHandler = (
    structureObj: CondominiumObject[]
  ): DropdownType[] => {
    const hasLetters = structureObj.some((item) => /\D/.test(item.name));

    const sorted = hasLetters
      ? structureObj.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }

          return 0;
        })
      : structureObj.sort((a, b) => {
          if (+a.name > +b.name) {
            return 1;
          }
          if (+a.name < +b.name) {
            return -1;
          }

          return 0;
        });

    return sorted
      .filter((item) => item.name !== "Interfone")
      .map((item) => {
        return { label: item.name, value: item.id };
      });
  };

  const getAllCondominium = async (resId: number) => {
    const data = await getCondominia(resId);

    setCondominiumList(listFilterHandler(data.condominia));
  };

  const getAllTower = useCallback(async (condId: number) => {
    setTowerList([]);
    setApartmentList([]);
    setContactList([]);
    setTower(null);
    setApartment(null);

    let data = await getTowers(condId);

    setTowerList(listFilterHandler(data.towers));

    setBackTo("condominiums");
  }, []);

  const getAllApartment = useCallback(async (towerId: number) => {
    setApartmentList([]);
    setContactList([]);
    setApartment(null);

    let data = await getApartmentsWithUsers(towerId);
    if (data.apartments.length > 0)
      setApartmentList(listFilterHandler(data.apartments));
    else {
      getToast(
        "Erro! Torre não possui apartamento com usuários ativos.",
        "error"
      );

      setBackTo("condominiums");
    }
  }, []);

  const getAllUsers = useCallback(async (apId: number) => {
    let data = await getUsersByApartment(apId);

    setContactList(data.users);
  }, []);

  const getNextStepComponents = useCallback(
    (
      currentCheckpoint: number,
      type: "condominium" | "tower" | "apartment",
      pageTitle: string
    ) => {
      const title = <span>{pageTitle}</span>;

      const placeholder =
        type === "apartment"
          ? "Buscar apartamento"
          : type === "tower"
          ? "Buscar torre"
          : "Buscar condomínio";

      setOutletParams({
        title,
        top: {
          component: (
            <SearchBar
              placeholder={placeholder}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          ),
          height: 7,
        },
        bottom: {
          component: (
            <ProgressBar parts={trackParts} current={currentCheckpoint} />
          ),
          height: 15,
          blurBindingDistance: 2,
        },
      });
    },
    [search, setOutletParams, trackParts]
  );

  const getContactComponents = useCallback(
    (towerLabel?: number | string, apartmentLabel?: number | string) => {
      setOutletParams({
        title: (
          <div className={styles["contacts-title"]}>
            <div>
              <HiOutlineUser />
            </div>
            <span>Selecione o contato</span>
          </div>
        ),
        top: {
          component: (
            <div className={styles["contacts-info"]}>
              {towerLabel && (
                <div className={styles["contacts-info-item"]}>
                  <p className={styles["contacts-info-label"]}>Torre</p>
                  <p className={styles["contacts-info-value"]}>{towerLabel}</p>
                </div>
              )}
              {apartmentLabel && (
                <div className={styles["contacts-info-item"]}>
                  <p className={styles["contacts-info-label"]}>Apartamento</p>
                  <p className={styles["contacts-info-value"]}>
                    {apartmentLabel}
                  </p>
                </div>
              )}
            </div>
          ),
          height: 8,
        },
        bottom: {
          component: null,
          height: 2,
        },
      });
    },
    [setOutletParams]
  );

  const getUsersKeys = useCallback(
    () => (
      <div
        className={classNames({
          [styles["one-column-grid"]]: true,
          [styles["cards-margin-bottom"]]: true,
          [styles["horizontal-padding"]]: true,
        })}
      >
        {contactList.map((item, i) => (
          <UserCard
            onCall={() => clearTimeout(timeoutId)}
            key={`user-card-${item.id}`}
            user={item}
          />
        ))}
      </div>
    ),
    [contactList, timeoutId]
  );

  const getApartmentKeys = useCallback(
    () => (
      <div className={styles["two-columns-grid"]}>
        {apartmentList
          .filter(
            (item) =>
              item.label
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase()) &&
              item.label !== "Interfone"
          )
          .map((item, i) => (
            <ButtonKey
              key={`apartment-key-${i}`}
              className={styles["apartment-key"]}
              height={100}
              action={() => {
                setSearch("");

                setApartment(item.value);
                getAllUsers(item.value);

                setBackTo("apartments");
              }}
              label={item.label}
            />
          ))}
      </div>
    ),
    [apartmentList, getAllUsers, search]
  );

  const getTowerKeys = useCallback(() => {
    const list = towerList.filter(
      (item) =>
        item.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()) &&
        item.label !== "Interfone"
    );
    const isOneGrid = list.some((item) => item.label.length > 3);

    return (
      <div
        style={{ width: "calc(400px + 2.5rem)" }}
        className={classNames({
          [styles["two-columns-grid"]]: !isOneGrid,
          [styles["one-column-grid-tower"]]: isOneGrid,
        })}
      >
        {list.map((item, i) => (
          <ButtonKey
            key={`tower-key-${i}`}
            className={styles["tower-key"]}
            height={isOneGrid ? 100 : 200}
            action={() => {
              setSearch("");

              setTower(item.value);
              getAllApartment(item.value);

              setBackTo("towers");
            }}
            label={item.label}
          />
        ))}
      </div>
    );
  }, [getAllApartment, search, towerList]);

  const getCondominiumKeys = useCallback(
    () => (
      <div className={styles["one-column-grid"]}>
        {condominiumList
          .filter(
            (item) =>
              item.label
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase()) &&
              item.label !== "Interfone"
          )
          .map((item, i) => (
            <ButtonKey
              key={`tower-key-${i}`}
              className={styles["tower-key"]}
              height={100}
              action={() => {
                setSearch("");

                setCondominium(item.value);
                getAllTower(item.value);

                setBackTo("condominiums");
              }}
              label={
                <div className={styles["condominium-key-label"]}>
                  <HiOutlineArrowCircleRight />
                  <span>{item.label}</span>
                </div>
              }
            />
          ))}
      </div>
    ),
    [condominiumList, getAllTower, search]
  );

  const setCondominiumKeys = useCallback(() => {
    getNextStepComponents(1, "condominium", "Selecione o condomínio");

    setMainContent(getCondominiumKeys());
  }, [getCondominiumKeys, getNextStepComponents]);

  const setTowerKeys = useCallback(() => {
    getNextStepComponents(
      trackParts.length === 4 ? 2 : 1,
      "tower",
      "Selecione a torre"
    );

    setMainContent(getTowerKeys());
  }, [getNextStepComponents, getTowerKeys, trackParts.length]);

  const setApartmentKeys = useCallback(() => {
    getNextStepComponents(
      trackParts.length === 4 ? 3 : 2,
      "apartment",
      "Selecione o apartamento"
    );

    setMainContent(getApartmentKeys());
  }, [getApartmentKeys, getNextStepComponents, trackParts.length]);

  const setContactKeys = useCallback(() => {
    const towerLabel = towerList.find((item) => item.value === tower)?.label;
    const apartmentLabel = apartmentList.find(
      (item) => item.value === apartment
    )?.label;

    getContactComponents(towerLabel, apartmentLabel);

    setMainContent(getUsersKeys());
  }, [
    apartment,
    apartmentList,
    getContactComponents,
    getUsersKeys,
    tower,
    towerList,
  ]);

  const backButtonAction = useCallback(() => {
    if (backTo === "home") {
      clearTimeout(timeoutId);
      navigate(-1);
    } else if (backTo === "condominiums") {
      if (trackParts.length === 3 || condominiumList.length === 1) {
        clearTimeout(timeoutId);
        navigate(-1);
      } else {
        setCondominium(null);

        setTower(null);
        setTowerList([]);
      }

      setBackTo("home");
    } else if (backTo === "towers") {
      if (towerList.length === 1) {
        if (trackParts.length === 3 || condominiumList.length === 1) {
          clearTimeout(timeoutId);
          navigate(-1);
        } else {
          setCondominium(null);
          setTowerList([]);
        }
      }
      setTower(null);

      setApartment(null);
      setApartmentList([]);

      setBackTo("condominiums");
    } else if (backTo === "apartments") {
      if (apartmentList.length === 1) {
        if (towerList.length === 1) {
          if (trackParts.length === 3 || condominiumList.length === 1) {
            clearTimeout(timeoutId);
            navigate(-1);
          } else {
            setCondominium(null);

            setTower(null);
            setTowerList([]);

            setApartmentList([]);
          }
        } else {
          setApartmentList([]);

          setTower(null);
        }
      }

      setApartment(null);

      setBackTo("towers");
    }
  }, [
    apartmentList.length,
    backTo,
    condominiumList.length,
    navigate,
    timeoutId,
    towerList.length,
    trackParts.length,
  ]);

  const handleScreenTimeout = useCallback(() => {
    const id = setTimeout(() => {
      navigate("/opcoes");
    }, 60000); // 1 min

    setTimeoutId(id);
  }, [navigate]);

  useEffect(() => {
    if (condominiumList.length > 0 && !condominium) {
      if (condominiumList.length === 1) {
        setCondominium(condominiumList[0].value);

        getAllTower(condominiumList[0].value);

        setBackTo("condominiums");
      } else setCondominiumKeys();
    } else if (towerList.length > 0 && !tower) {
      if (towerList.length === 1) {
        setTower(towerList[0].value);

        getAllApartment(towerList[0].value);

        setBackTo("towers");
      } else setTowerKeys();
    } else if (apartmentList.length > 0 && !apartment) {
      if (apartmentList.length === 1) {
        setApartment(apartmentList[0].value);

        getAllUsers(apartmentList[0].value);

        setBackTo("apartments");
      } else setApartmentKeys();
    } else if (contactList.length > 0) {
      setContactKeys();
      setBackTo("apartments");
    }
  }, [
    apartment,
    apartmentList,
    condominium,
    condominiumList,
    search,
    tower,
    towerList,
    contactList,
  ]);

  useEffect(
    () => setBackButton({ action: () => backButtonAction() }),
    [backButtonAction]
  );

  useEffect(() => {
    handleScreenTimeout();

    if (user.automation?.Door.condominiumId) {
      setTrackParts(["Torre", "Apartamento", undefined]);
      getAllTower(user.condominiumId);
    } else {
      setTrackParts(["Condomínio", "Torre", "Apartamento", undefined]);
      getAllCondominium(user.residentialId);
    }
  }, []);

  return (
    <div id="intercom">
      <audio src={welcomeMp3} autoPlay />
      {mainContent}
    </div>
  );
}
